import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/builds/kulak.technology/midival-docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "getting-started"
    }}>{`Getting Started`}</h1>
    <p>{`Quick introduction to what `}<inlineCode parentName="p">{`@midival`}</inlineCode>{` is.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`2 + 2
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      